import { VIcon } from "vuetify/components";

export type Status = "plain" | "success" | "warning" | "error";

export default defineComponent({
  name: "OrderArchive",
  props: {
    status: { type: String as PropType<Status> },
    partial: { type: Boolean, default: false },
    empty: { type: Boolean, default: false },
    color: { type: String },
  },
  setup(props) {
    const useColor = computed(() => {
      return match(props.status, {
        success: "green",
        warning: "orange",
        error: "red",
        default: props.color || "grey",
      });
    });

    const useIcon = computed(() => {
      if (props.partial) return "contrast";
      if (props.empty) return "panorama_fish_eye";
      return "lens";
    });

    return {
      useColor,
      useIcon,
    };
  },
  render() {
    return h(
      VIcon,
      {
        ...this.$props,
        color: this.useColor,
        size: 10,
      },
      this.useIcon
    );
  },
});
