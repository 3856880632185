import { omit, pick } from "lodash";
import { VChip } from "vuetify/components";
import Icon, { type Status } from "./Icon";

export type StatusProps = {
  status: Status;
  color?: string;
  iconColor?: string;
  variant?: string;
  text: string;
  partial?: boolean;
  empty?: boolean;
  [p: string]: any;
};

export default defineComponent({
  props: {
    ...VChip.props,
    size: { type: [String, Number], default: "x-small" },
  },
  methods: {
    getStatus(): StatusProps {
      return {
        status: "plain",
        text: "",
      };
    },
  },
  computed: {
    status(): StatusProps {
      return this.getStatus();
    },
  },
  render() {
    return h("span", this.$attrs, [
      h(
        VChip,
        {
          ...omit(this.$props, ["item"]),
          ...omit(this.status, ["text", "partial", "empty", "iconColor"]),
          title: this.status.title,
          class: "flex-align px-2 fit-content",
        },
        [
          h(Icon, {
            status: this.status.status,
            partial: this.status.partial,
            empty: this.status.empty,
            color: this.status.iconColor,
            style: "padding-top: 0.5px",
          }),
          h(
            "span",
            { class: "ml-1 text-uppercase font-semibold" },
            this.status.text
          ),
        ]
      ),
    ]);
  },
});
